import React, { useEffect, useState } from "react";

import PropTypes, { object } from "prop-types";
import { connect } from "react-redux";
import {
  Dialog,
  Typography,
  Button,
  Card,
  CardBody,
  CardFooter,
  Input,
  CardHeader,
  Chip,
  Tooltip,
  IconButton,
} from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

import {
  ArchiveBoxIcon,
  PlayIcon,
  RectangleStackIcon,
  StopIcon,
} from "@heroicons/react/24/solid";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Select, Option } from "@material-tailwind/react";
import Divider from "@mui/material/Divider";
import {
  loadUserList,
  changeStatus,
  deleteUser,
  editEmailCount,
} from "../../actions/adminAction";
import Sidebar from "../layout/Sidebar";
import Spinner from "../layout/Spinner";

import { logout } from "../../actions/authAction";
import { useIdleDetection } from "../Inactive/useIdleDetection";

const UserManage = ({
  user,
  user_list,
  loadUserList,
  changeStatus,
  deleteUser,
  editEmailCount,
  loading,
  logout,
}) => {
  const { t } = useTranslation();
  const isIdle = useIdleDetection(600000); // 10 minutes

  const TABLE_HEAD = [
    t("lang.tableHead.1"),
    t("lang.tableHead.2"),
    t("lang.tableHead.3"),
    t("lang.tableHead.4"),
    t("lang.tableHead.5"),
    t("lang.tableHead.6"),
    t("lang.tableHead.7"),
    t("lang.tableHead.8"),
  ];

  const [openCnt, setOpenCnt] = React.useState(false);
  const [pageSize, setPageSize] = useState("5");

  const [email_count, setEmailCount] = useState("");
  const [personal_email, setEmail] = useState("");
  const handleOpenCnt = () => setOpenCnt((cur) => !cur);

  if (isIdle) {
    logout();
  }

  // const handlePageSize = (value) => {
  //   setPageSize(value);
  // };
  const onChangeCnt = (e) => {
    setEmailCount(e.target.value);
  };

  useEffect(() => {
    loadUserList({
      id: user.id,
      user_type:
        typeof user.user_type === "object" ? user.user_type.id : user.user_type,
    });
    setLastIndex(pageSize - 1);
  }, [pageSize]);
  const [search, setSearch] = useState("");
  const [firstIndex, setFirstIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(4);
  const [pageIndex, setPageIndex] = useState(0);

  const user_listData = user_list.length !== 0 ? user_list.data.users : [];
  let getData = [];
  let searchData = [];

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handlePrevious = () => {
    const newIndex = Math.max(0, firstIndex - parseInt(pageSize));
    setFirstIndex(newIndex);
    setLastIndex(
      Math.min(user_listData.length - 1, newIndex + parseInt(pageSize) - 1)
    );
    setPageIndex(newIndex);
  };

  const handleNext = () => {
    if (lastIndex < user_listData.length - 1) {
      const newIndex = Math.min(user_listData.length - 1, lastIndex + 1);
      setFirstIndex(newIndex);
      setLastIndex(
        Math.min(user_listData.length - 1, newIndex + parseInt(pageSize) - 1)
      );
      setPageIndex(newIndex);
    }
  };

  const handlePageSize = (value) => {
    setPageSize(value);
  };

  let countData = 0;

  for (let i = 0; i < user_listData.length; i++) {
    if (
      user_listData[i].first_name == null ||
      user_listData[i].last_name == null ||
      user_listData[i].email == null
    ) {
      continue;
    } else if (
      (user_listData[i] &&
        user_listData[i].first_name
          .toLowerCase()
          .includes(search.toLowerCase())) ||
      user_listData[i].last_name.toLowerCase().includes(search.toLowerCase()) ||
      user_listData[i].email.toLowerCase().includes(search.toLowerCase())
    ) {
      searchData.push(user_listData[i]);
      countData += 1;
    }
  }

  const get_table_data = (firstIndex, lastIndex) => {
    for (let i = firstIndex; i <= lastIndex; i++) {
      if (searchData[i]) {
        getData.push(searchData[i]);
      }
    }
  };
  get_table_data(firstIndex, lastIndex);
  const TABLE_ROWS = getData.map(
    (
      {
        id,
        first_name,
        last_name,
        email,
        country_code,
        user_status,
        email_count,
        mobile,
      },
      index
    ) => {
      return {
        id,
        no: pageIndex + index + 1,
        first_name,
        last_name,
        email,
        country_code:
          country_code === null
            ? "N/A"
            : country_code + " " + mobile === null
            ? "N/A"
            : mobile,
        user_status: user_status == null ? 0 : user_status.id,
        email_count,
      };
    }
  );

  const onSave = (data) => {
    handleOpenCnt();
    editEmailCount({ personal_email, email_count });
  };

  const handleDoubleClick = (email_count, personal_email) => {
    handleOpenCnt();
    setEmailCount(email_count);
    setEmail(personal_email);
  };
  return (
    <div className="flex flex-col lg:flex-row pb-6 w-full">
      <Sidebar />
      <Divider orientation="vertical" flexItem />
      <div className="w-full flex flex-col">
        <Card className="shadow-none">
          <CardHeader
            floated={false}
            shadow={false}
            className="rounded-none m-0"
          >
            <div className="flex flex-col justify-between md:flex-row md:items-center p-4 pb-0">
              <Typography variant="h5" color="blue-gray">
                {t("lang.adminSidebar.text")}
              </Typography>
              <div>
                {t("lang.userManage.obj2")}
                <b>{countData}</b>
              </div>
              <div className="w-full md:w-72">
                <Input
                  id="search"
                  onChange={handleSearch}
                  label={t("lang.userManage.obj4")}
                  icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                />
              </div>
            </div>
          </CardHeader>
          {loading ? (
            <div className="w-full flex justify-center">
              <Spinner />
            </div>
          ) : (
            <>
              <CardBody className="px-0">
                <div className="overflow-auto h-[60dvh]">
                  <table className="w-full pb-6 text-center">
                    <thead>
                      <tr className="border-y border-black-gray-100 bg-black-gray-50">
                        {TABLE_HEAD.map((head) => (
                          <th key={head} className="p-4">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal leading-none opacity-70"
                            >
                              {head}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {TABLE_ROWS.map(
                        (
                          {
                            id,
                            no,
                            first_name,
                            last_name,
                            email,
                            country_code,
                            user_status,
                          },
                          index
                        ) => {
                          const isLast = index === TABLE_ROWS.length - 1;
                          const classes = isLast
                            ? "p-4"
                            : "p-4 border-b border-blue-gray-50";

                          return (
                            <tr key={no}>
                              <td className={classes}>
                                <div className="flex items-center gap-3">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-bold"
                                  >
                                    {no}
                                  </Typography>
                                </div>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {first_name}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {last_name}
                                </Typography>
                              </td>

                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {email}
                                </Typography>
                              </td>

                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {country_code}
                                </Typography>
                              </td>

                              <td className={classes}>
                                <Chip
                                  size="sm"
                                  variant="ghost"
                                  value={
                                    user_status == 2 ? "Blocked" : "active"
                                  }
                                  color={user_status == 1 ? "green" : "red"}
                                />
                              </td>
                              {user_status == 1 ? (
                                <td className={classes}>
                                  <Tooltip content="Block User">
                                    <IconButton
                                      variant="text"
                                      color="gray"
                                      onClick={() =>
                                        changeStatus({
                                          user_id: user.id,
                                          type: user.user_type,
                                          id: id,
                                          status: 2,
                                        })
                                      }
                                    >
                                      <StopIcon className="h-4 w-4" />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                              ) : (
                                <td className={classes}>
                                  <Tooltip content="Unblock User">
                                    <IconButton
                                      variant="text"
                                      color="gray"
                                      onClick={() =>
                                        changeStatus({
                                          user_id: user.id,
                                          type: user.user_type,
                                          id: id,
                                          status: 1,
                                        })
                                      }
                                    >
                                      <PlayIcon className="h-4 w-4" />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                              )}
                              <td className={classes}>
                                <Tooltip content="Delete User">
                                  <IconButton
                                    variant="text"
                                    color="blue-gray"
                                    onClick={() =>
                                      deleteUser({
                                        user_id: user.id,
                                        type: user.user_type,
                                        id: id,
                                      })
                                    }
                                  >
                                    <ArchiveBoxIcon className="h-4 w-4" />
                                  </IconButton>
                                </Tooltip>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </CardBody>
              <CardFooter className="flex flex-col md:flex-row space-y-3 md:space-y-0 items-center justify-between border-t border-black-gray-50 p-4">
                <Button
                  variant="outlined"
                  color="gray"
                  size="sm"
                  onClick={handlePrevious}
                  className="w-full md:w-auto"
                >
                  Previous
                </Button>
                <div className="w-full md:w-auto">
                  <Select
                    label={t("lang.pageFooter.obj3")}
                    value={pageSize}
                    onChange={handlePageSize}
                  >
                    <Option value={"5"}>5</Option>
                    <Option value={"10"}>10</Option>
                    <Option value={"20"}>20</Option>
                    <Option value={"50"}>50</Option>
                    <Option value={"100"}>100</Option>
                  </Select>
                </div>

                <Button
                  variant="outlined"
                  color="gray"
                  size="sm"
                  onClick={handleNext}
                  className="w-full md:w-auto"
                >
                  Next
                </Button>
              </CardFooter>
            </>
          )}
        </Card>
      </div>
    </div>
  );
};

UserManage.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func.isRequired,
  loadUserList: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  editEmailCount: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  user_list: state.adminReducer.users,
  loading: state.loadReducer.loading,
});

export default connect(mapStateToProps, {
  loadUserList,
  changeStatus,
  deleteUser,
  editEmailCount,
  logout,
})(UserManage);
