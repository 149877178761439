import api from "../utils/api";
import { loadUser, logout } from "./authAction";
import { setAlert } from "./alertAction";
import { LOADING, PAY_CRED_IDS } from "./types";
import i18n from "../i18n";

export const contactUs = (formData) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const { language } = i18n;
    formData.language = language;
    console.log(formData);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch({ type: LOADING, payload: true });
    const res = await api.post("/contactUs", formData, config);
    dispatch({ type: LOADING, payload: false });
    dispatch(setAlert(i18n.t(`response.${res.data.key}`), "green"));
  } catch (error) {
    if (error) {
      dispatch({ type: LOADING, payload: false });
      dispatch(setAlert(i18n.t(`response.${error.response.data.key}`), "red"));
    }
  }
};

export const updateProfile =
  ({ first_name, last_name, country_code, mobile, user_id, email }) =>
  async (dispatch) => {
    try {
      const { language } = i18n;
      const token = sessionStorage.getItem("token");

      // Set token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      dispatch({ type: LOADING, payload: true });
      const res = await api.patch(
        "/updateProfile",
        {
          first_name,
          last_name,
          country_code,
          mobile,
          user_id,
          email,
          language,
        },
        config
      );

      //dispatch(setAlert("Your profile was updated successfully!", "green"));
      dispatch(loadUser(user_id, email));
      dispatch({ type: LOADING, payload: false });
      dispatch(setAlert(i18n.t(`response.${res.data.key}`), "green"));
      return res.data;
    } catch (error) {
      if (error) {
        dispatch({ type: LOADING, payload: false });
        dispatch(
          setAlert(i18n.t(`response.${error.response.data.key}`), "red")
        );
      }
    }
  };

export const changePassword = (formData) => async (dispatch) => {
  try {
    const { newPassword, confirmPassword } = formData;
    if (newPassword != confirmPassword) {
      dispatch(setAlert("New password does not match!", "red"));
      return;
    }

    dispatch({ type: LOADING, payload: true });
    const res = await api.post("/user/changePassword", formData);
    dispatch({ type: LOADING, payload: false });
    dispatch(setAlert("Your password was changed successfully!", "green"));
    return res.data;
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      dispatch({ type: LOADING, payload: false });
      errors.forEach((error) =>
        dispatch(setAlert(i18n.t(`response.${error.response.data.key}`), "red"))
      );
    }
  }
};

export const pricingDetails = async () => {
  try {
    const res = await api.get("/plandata");
    const { data } = res;
    const { data: plans } = data;
    return Object.values(plans);
  } catch (error) {
    if (error) {
      console.log(error);
    }
  }
};

export const createSubsctiption = (forUpgrade) => async (dispatch) => {
  const { user_id } = forUpgrade;
  try {
    const { language } = i18n;
    const token = sessionStorage.getItem("token") ?? "";
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch({ type: LOADING, payload: true });

    const res = await api.post(
      "/create-subscription",
      { ...forUpgrade, language },
      config
    );

    dispatch({
      type: PAY_CRED_IDS,
      payload: res.data.data,
    });

    dispatch(loadUser(user_id));
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    return error;
  }
};

export const cancelSubscription = (userId) => async (dispatch) => {
  try {
    const { language } = i18n;
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    dispatch({ type: LOADING, payload: true });

    const res = await api.post(
      "/delete-subscription",
      { user_id: userId, language },
      config
    );

    dispatch(loadUser(userId));

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
  }
};

export const deleteAccount =
  ({ userType, id }) =>
  async (dispatch) => {
    try {
      const { language } = i18n;
      const token = sessionStorage.getItem("token");
      const loginId = sessionStorage.getItem("login_id");

      const formData = {
        login_user_id: loginId,
        user_type: userType,
        user_id: id,
        language,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      dispatch({ type: LOADING, payload: true });
      const res = await api.delete("/deleteUser", {
        data: formData,
        ...config,
      });

      logout();
      dispatch({ type: LOADING, payload: false });
      dispatch(setAlert(i18n.t(`response.${res.data.key}`), "green"));
    } catch (error) {
      dispatch(setAlert(i18n.t(`response.${error.response.data.key}`), "red"));
    }
  };
